<template>
  <div class="tree-result-filter card">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-8">
          <h5 class="h5" v-if="dataSelectedCategory && dataSelectedCategory.id > 0">
            {{ $t('tasks.assessment.selected_tasks_title') }}:
            {{ dataSelectedCategory.name }}
          </h5>
          <h5 style="margin-bottom:5px;" v-else>
            {{ $t('tasks.assessment.all_tasks_title') }}
          </h5>
          <p v-if="dataSelectedCategory.description && dataSelectedCategory.description.length">
            {{ dataSelectedCategory.description }}
          </p>
        </div>
        <div class="col-sm-4 text-right">
          <font-awesome-icon icon="check-circle" class="icon-success" v-if="dataSelectedCategory.status == 'Pass'"/>
          <font-awesome-icon icon="times-circle" class="icon-danger" v-if="dataSelectedCategory.status == 'Fail'"/>
          <font-awesome-icon icon="info-circle" class="icon-info" v-if="dataSelectedCategory.status == 'In progress'"/>
          <span class="pl-1 pr-4">{{dataSelectedCategory.status}}</span>
          <button class="text-muted btn btn-edit"
                  qid="edit-activity-modal"
                  @click="showEditCategory(dataSelectedCategory)"
          >
            <font-awesome-icon icon="pen"/>
          </button>
          <button class="text-muted btn btn-edit"
                  qid="edit-activity-modal"
                  @click="showCategoryConfirmDeleteModal(dataSelectedCategory)"
          >
            <font-awesome-icon icon="trash-alt"/>
          </button>
        </div>
      </div>
    </div>


    <!-- View measure category -->
    <b-modal ref="view-category" modal-class="task-modal" @hide="hideViewMCModal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
            <span @click="showEditModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
              <font-awesome-icon icon="times"/>
            </span>
          <h5 class="mb-1 text-center" qid="view-mc-title">
            {{ $t('maintenance.measure_categories.view.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>

      <label class="mt-3">
        {{ $t('maintenance.measure_categories.create.name_label') }}
      </label>
      <br>
      <span v-if="dataSelectedCategory.name" qid="view-mc-name">
          {{ dataSelectedCategory.name }}
        </span>
      <br>

      <label class="mt-3" v-if="dataSelectedCategory && dataSelectedCategory.scope">
        {{ $t('maintenance.measure_categories.create.scope_label') }}
      </label>
      <br>
      <span v-if="dataSelectedCategory && dataSelectedCategory.scope" qid="view-mc-scope">
          <span>
            <span v-for="(scope, index) in dataSelectedCategory.scope" :key="'view_mc_scope_' + index">
              <span v-if="scope == 'activity'" class="mr-1 text-normal">
                <font-awesome-icon icon="cubes"/>
                  {{ $t('projects.scope.activity') }}
              </span>

              <span v-if="scope == 'system'" class="text-normal ml-2">
                <font-awesome-icon icon="desktop"/>
                  {{ $t('projects.scope.system') }}
              </span>

              <span v-if="scope == 'third_party'" class="text-normal ml-2">
                <font-awesome-icon icon="exchange-alt"/>
                  {{ $t('projects.scope.third_party') }}
              </span>
            </span>
          </span>
        </span>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="hideViewMCModal"
                            qid="view-mc-cancel-button"
                  >
                    {{ $t('processes.policies.view.close') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- Edit Control modal -->
    <b-modal ref="edit-control" hide-footer modal-class="task-modal" @hide="hideEditModal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="update-user-title">
            {{ $t('testing.form.update_control_objective') }}
          </h5>
        </div>
      </template>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="updateFormSubmit" qid="create-p-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-v-name-label">
              {{ $t('testing.form.title') }}
              <span class="text-red">*</span>
            </label>
            <b-form-input
                    qid="create-v-form-name"
                    type="text"
                    v-model="dataSelectedCategory.name"
                    required
                    :maxLength="255"
                    :placeholder="$t('testing.form.title')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-v-description-label">
              {{ $t('testing.form.description') }}
              <span class="text-red">*</span>
            </label>
            <b-form-textarea
                    qid="create-v-form-description"
                    v-model="dataSelectedCategory.description"
                    rows="6"
                    max-rows="6"
                    required
                    :placeholder="$t('testing.form.description')"
            ></b-form-textarea>
          </b-form-group>
        </div>
        <div class="proxy-footer">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class="mt-3"
                            variant="secondary"
                            @click="hideEditModal"
                            qid="create-p-cancel-button"
                  >
                    {{ $t('projects.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="mt-3 ml-3"
                            variant="success"
                            :disabled="buttonDisabled"
                            qid="create-p-submit-button"
                  >
                  <span v-if="buttonDisabled" class="mr-1">
                    <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                  </span>
                    {{ $t('projects.create.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </b-form>

    </b-modal>

    <!-- Create test modal -->
    <b-modal ref="edit-category"
             @hide="hideEditCategory"
             size="xl"
             modal-class="bottom-left task-modal multi-color-modal">
      <template #modal-header="{ close }" class="alert-menu-color text-white">
        <div style="width: 100%;">
            <span @click="showEditForm" class="btn btn-secondary cursor-pointer float-right">
            <font-awesome-icon icon="cog"/>
          </span>
          <h5 class="mb-1 text-center text-white">
            {{ dataSelectedCategory.name }}
          </h5>

          <!-- Header text Types-->
          <div class="row mt-3 pt-4 pb-1" v-if="dataSelectedCategory">
            <div class="col-sm-12">
              <b-form-group>
                <div class="">

                  <!-- Type 1 main task-->
                  <div>
                    <p v-if="dataSelectedCategory.description"
                       class="text-formatted"
                    >
                      {{ dataSelectedCategory.description }}
                    </p>
                  </div>

                </div>

              </b-form-group>

            </div>
          </div>
        </div>
      </template>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>

      <div class="row">
        <!--Control objective status-->
        <div class="col-sm-6">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title border-bottom pb-2">
                <div class="row">
                  <div class="col-md-6">
                    {{ $t('system.control_objective_status') }}
                  </div>

                </div>
              </h5>

              <b-form-group>

                <span class="btn text-success cursor-pointer float-right"
                      @click="editModeResponse = !editModeResponse"
                      v-if="!editModeResponse"
                >
                      <font-awesome-icon icon="pen" class="text-success"/> Edit
                    </span>

                <span
                        class="btn text-danger cursor-pointer float-right"
                        @click="discardStatus()"
                        v-if="editModeResponse">
                              Discard
                            </span>

                <span
                        class="btn text-success cursor-pointer float-right"
                        @click="updateStatus()"
                        v-if="editModeResponse">
                                  Save
                            </span>

                <label qid="assessment-form-response-label" style="font-size: medium;" class="mt-3">
                  {{ $t('system.status') }}
                </label>

                <div v-if="!editModeResponse">
                  <font-awesome-icon icon="check-circle" class="icon-success"
                                     v-if="dataSelectedCategory.status == 'Pass'"/>
                  <font-awesome-icon icon="times-circle" class="icon-danger"
                                     v-if="dataSelectedCategory.status == 'Fail'"/>
                  <font-awesome-icon icon="info-circle" class="icon-info"
                                     v-if="dataSelectedCategory.status == 'In progress'"/>
                  <span class="pl-1 pr-4">{{dataSelectedCategory.status}}</span>
                </div>


                <multiselect
                        v-if="editModeResponse"
                        v-model="selectedStatus"
                        qid="assessment-form-response-drop-down"
                        :options="allStatuses"
                        :multiple="false"
                        :taggable="false"
                        label="name"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        :placeholder="$t('tasks.modal.response_placeholder')"
                >
                </multiselect>


                <label qid="assessment-form-response-label" style="font-size: medium;" class="mt-3">
                  {{ $t('system.control_objective_comment') }}
                </label>

                <b-form-textarea
                        qid="assessment-form-control-description"
                        v-model="dataSelectedCategory.comment"
                        v-if="editModeResponse"
                        rows="6"
                        max-rows="6"
                        :placeholder="$t('tasks.modal.control_description_placeholder')"
                ></b-form-textarea>

                <div v-if="!editModeResponse">
                  {{ dataSelectedCategory.comment }}
                </div>

              </b-form-group>


            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title border-bottom pb-2">
                <div class="row">
                  <div class="col-md-6">
                    {{ $t('system.effectiveness') }}
                  </div>

                </div>
              </h5>

              <div v-for="(object, index) in open_tasks"
                   :key="index"
                   class="mb-4">
                <div class="row">

                  <div class="col-md-1 text-left">

                    <span v-if="object.response">


                          <font-awesome-icon icon="check-circle" class="icon-success"
                                             v-if="getIcon(object) == 'Positive'"/>
                          <font-awesome-icon icon="times-circle" class="icon-danger"
                                             v-if="getIcon(object) == 'Negative'"/>
                          <font-awesome-icon icon="info-circle" class="icon-info"
                                             v-if="getIcon(object) == 'Neutral'"/>
                          <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'"
                                             v-if="getIcon(object) == 'Very low'"/>
                          <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'"
                                             v-if="getIcon(object) == 'Low'"/>
                          <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'"
                                             v-if="getIcon(object) == 'High'"/>
                          <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'"
                                             v-if="getIcon(object) == 'Very high'"/>

                    </span>
                    <span v-else>
                      <span v-if="object.status === 'pending'">
                        <span class="pr-2">
                          <font-awesome-icon
                                  icon="exclamation-triangle"
                                  class="risk-6"
                          />
                        </span>

                      </span>
                    </span>
                  </div>
                  <div class="col-md-11">
                    {{ object.full_title }}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- FOOTER -->
      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">

          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">
              </div>
              <div class="col-sm-6 text-right">
                <b-button class="mt-2"
                          variant="secondary"
                          @click="hideEditCategory"
                          qid="re-assign-cancel-button"
                >
                  {{ $t('projects.create.cancel') }}
                </b-button>
                <b-button class="ml-3 mt-2"
                          variant="success"
                          @click="updateStatus()"
                          :disabled="buttonDisabled"
                          qid="task-modal-save-button"
                >
                  {{ $t('system.save') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </template>

    </b-modal>

    <!-- Delete task modal template -->
    <b-modal ref="delete-category" modal-class="task-modal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideCategoryConfirmDeleteModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="delete-task-title">
            {{ $t('activities.system.delete.title') }}
          </h5>
        </div>
      </template>

      <div class="mb-2 mt-3" style="font-size:18px;" v-if="itemToDelete && itemToDelete.id">
        <div>
          {{ $t('activities.system.delete.description') }}
          <span class="text-bold">
            {{ itemToDelete.name }}
          </span>
        </div>
        <div class="mt-2">
          {{ $t('system.category_delete_message') }}
        </div>
        <div class="mt-2 text-center">
          {{ $t('maintenance.measures.delete.are_you_sure') }}
        </div>
      </div>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <span class="btn btn-secondary cursor-pointer"
                        @click="hideCategoryConfirmDeleteModal"
                        qid="delete-task-cancel-button"
                  >
                    {{ $t('maintenance.measures.delete.cancel') }}
                  </span>
                  <b-button type="submit"
                            class="ml-3"
                            variant="danger"
                            @click="confirmDeleteCategory(itemToDelete)"
                            :disabled="buttonDisabled"
                            qid="delete-task-submit-button"
                  >
                    {{ $t('maintenance.measures.delete.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>

  </div>
</template>
<font-awesome-icon icon="check-circle" class="icon-success" v-if="dataSelectedCategory.status == 'Pass'"/>
<font-awesome-icon icon="times-circle" class="icon-danger" v-if="dataSelectedCategory.status == 'Fail'"/>
<font-awesome-icon icon="info-circle" class="icon-info" v-if="dataSelectedCategory.status == 'In progress'"/>
<script>
  import piincHttp from '@/util/configureAxios.js'

  export default {
    name: 'MeasureTopBar',
    data: function () {
      return {
        // loading: true,
        MCForm: {
          name: '',
          scope: []
        },
        MCForm: {
          name: '',
          scope: []
        },
        error: '',
        selectedScopes: [],
        scopes: [
          {name: this.$t('projects.scope.activity'), value: 'activity'},
          {name: this.$t('projects.scope.system'), value: 'system'},
          {name: this.$t('projects.scope.third_party'), value: 'third_party'}
        ],
        selectedStatus: [],
        allStatuses: [
          {name: 'Pass', value: 'Pass'},
          {name: 'Fail', value: 'Fail'},
          {name: 'In progress', value: 'In progress'}
        ],
        buttonDisabled: false,
        itemToDelete: {},
        // copy of selectedCategory prop
        dataSelectedCategory: {},
        dataSelectedProject: {},
        editModeResponse: false,
        open_tasks: {},
      }
    },
    props: {
      selectedCategory: {},
      selectedProject: {},
    },
    watch: {

      selectedCategory: function (newValue, oldValue) {
        // Watching the prop and setting it's copy object which is used in this component
        if (newValue) {
          this.dataSelectedCategory = newValue
        } else {
          this.dataSelectedCategory = {}
        }
      },
      selectedProject: function (newValue, oldValue) {
        // Watching the prop and setting it's copy object which is used in this component
        if (newValue) {
          this.dataSelectedProject = newValue
        } else {
          this.dataSelectedProject = {}
        }
      },
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
    },
    methods: {
      updateFormSubmit(evt) {
        let self = this;
        evt.preventDefault();
        piincHttp.put('project-control-objectives/' + self.dataSelectedCategory.id, self.dataSelectedCategory).then(function (response) {
          piincHttp.get('project-control-objectives/' + self.dataSelectedCategory.id).then(function (response) {
            self.dataSelectedCategory = response.data
          }, function (error) {

          })
          self.$refs['edit-control'].hide();
        }, function (error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      hideEditModal() {
        self.$refs['edit-control'].hide();
      },
      showEditForm() {
        this.buttonDisabled = true;
        let self = this;
        self.$refs['edit-control'].show();
        self.buttonDisabled = false;
      },
      getIcon(object) {
        let foundObject = {}
        let responseOptions = JSON.parse(object.response_type_options)

        foundObject = _.find(responseOptions, function(item) {
          return item.title === object.response;
        });

        if (foundObject) {
          return foundObject.value.value
        } else {
          return ''
        }

      },
      discardStatus() {
        this.editModeResponse = false
      },
      updateStatus() {
        let self = this;

        this.dataSelectedCategory.status = self.selectedStatus.value
        piincHttp.put('project-control-objectives/' + this.dataSelectedCategory.id, this.dataSelectedCategory).then(function (response) {
          self.$emit('forceSideFilterRenderComponent', self.dataSelectedCategory)
          self.$refs['edit-category'].hide();
        }, function (error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })

      },
      confirmDeleteCategory(object) {
        let self = this;
        piincHttp.delete('project-control-objectives/' + object.id).then(function (response) {
          self.$emit('forceSideFilterRenderComponent', self.dataSelectedCategory)
          self.hideCategoryConfirmDeleteModal();

        }, function () {
        });
      },
      // Measure category methods
      showViewMCModal() {
        this.$refs['view-category'].show()
      },
      hideViewMCModal() {
        this.$refs['view-category'].hide()
      },

      // Edit MC
      showEditMCModal(object) {
        let self = this;
        self.selectedScopes = [];
        this.error = '';
        if (object.scope && object.scope.length) {

          _.forEach(object.scope, function (value, key) {
            _.find(self.scopes, function (item) {
              if (item.value === value) {
                self.selectedScopes.push(item)
              }
            });
          })
        }

        this.MCForm.slug = object.slug;
        this.MCForm.name = object.name;
        this.$refs['edit-category'].show()
      },

      // Edit category
      showEditCategory(object) {
        let self = this;

        this.loadTasks()
        _.find(self.allStatuses, function (item) {
          if (item.value === self.dataSelectedCategory.status) {
            self.selectedStatus = item
          }
        });


        self.$refs['edit-category'].show()
      },

      showCategoryConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.$refs['delete-category'].show()
      },

      hideCategoryConfirmDeleteModal() {
        this.itemToDelete = []
        this.$refs['delete-category'].hide()
      },

      hideEditMCModal() {
        this.error = ''
        this.openCategory = {};
        this.$refs['edit-category'].hide()
      },


      hideEditCategory() {
        this.error = ''
        this.openCategory = {};
        this.$refs['edit-category'].hide()
      },

      editMCFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();
        let scopes = []

        // Append list of types
        if (this.selectedScopes && this.selectedScopes.length) {
          for (var i = 0; i < this.selectedScopes.length; i++) {
            scopes.push(this.selectedScopes[i].value)
          }
        }

        this.MCForm.client = this.getClient.slug;
        this.MCForm.scope = scopes;

        if (this.MCForm.scope.length && this.MCForm.name && this.MCForm.name.length) {
          piincHttp.put('measure-categories/' + this.MCForm.slug, this.MCForm).then(function (response) {
            self.hideEditMCModal();
            self.buttonDisabled = false;
            // Store new value from API for updated category
            self.dataSelectedCategory = response.data
            // Emit event together with new value
            self.$emit('forceSideFilterRenderComponent', self.dataSelectedCategory)
            self.$toastr('success', self.$t('maintenance.measure_categories.update.success'))
          }, function (error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.buttonDisabled = false;
          this.error = ''
          if (this.MCForm.name == '') {
            this.error += ' ' + this.$t('maintenance.incidents.incident_categories.create.name_error')
          }
          if (this.selectedScopes && !this.selectedScopes.length) {
            this.error += ' ' + this.$t('system.scope_error_message')
          }
        }
      },
      // Delete MC
      showConfirmDeleteMCModal(object) {
        this.itemToDelete = object
        this.$refs['delete-category'].show()
      },
      hideConfirmDeleteMCModal() {
        this.itemToDelete = {}
        this.$refs['delete-category'].hide()
      },
      confirmDeleteMC(object) {
        let self = this;
        piincHttp.delete('measure-categories/' + object.slug).then(function (response) {
          self.hideConfirmDeleteMCModal();
          self.$emit('forceSideFilterRenderComponent', null)
          // Trigger toastr message after MCModal close
          self.$toastr('success', self.$t('maintenance.measure_categories.delete.success'))
        }, function () {
        });
      },
      loadTasks(page, perPage) {
        let self = this;
        this.loadingTasks = true;

        if (this.getStorePerPage) {
          this.tasksSelectedPerPage = this.getStorePerPage;
        }

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.tasksSelectedPerPage;


        // Call API for open tasks
        piincHttp.get('projects/' + this.dataSelectedProject.slug + '/tasks', {
          params:
            {
              page: pageNumber,
              not_code: 'update_measures',
              control_objective: this.dataSelectedCategory && this.dataSelectedCategory.id ? this.dataSelectedCategory.id : undefined,
              per_page: perPageNumber,
            }
        }).then(function (response) {
          self.open_tasks = response.data.items;
          // Set number of total items
          self.tasksTotalRows = response.data.total_count;
          self.loadingTasks = false;
          self.loadFirstTime = false;
        }, function () {
        });

      }
    },
    created() {
      let self = this;
      // Create a copy of prop object to avoid directly mutating a prop (throws error)
      this.dataSelectedCategory = this.selectedCategory
      this.dataSelectedProject = this.selectedProject
    }
  }
</script>
