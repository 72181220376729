import { render, staticRenderFns } from "./AssessmentTaskTopBar.vue?vue&type=template&id=1c86145c&"
import script from "./AssessmentTaskTopBar.vue?vue&type=script&lang=js&"
export * from "./AssessmentTaskTopBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./AssessmentTaskTopBar.vue?vue&type=custom&index=0&blockType=font-awesome-icon&icon=check-circle&class=icon-success&v-if=dataSelectedCategory.status%20%3D%3D%20'Pass'"
if (typeof block0 === 'function') block0(component)
import block1 from "./AssessmentTaskTopBar.vue?vue&type=custom&index=1&blockType=font-awesome-icon&icon=times-circle&class=icon-danger&v-if=dataSelectedCategory.status%20%3D%3D%20'Fail'"
if (typeof block1 === 'function') block1(component)
import block2 from "./AssessmentTaskTopBar.vue?vue&type=custom&index=2&blockType=font-awesome-icon&icon=info-circle&class=icon-info&v-if=dataSelectedCategory.status%20%3D%3D%20'In%20progress'"
if (typeof block2 === 'function') block2(component)

export default component.exports